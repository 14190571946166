.mainContainer {
  padding: 20px;
}

.heading {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  color: #972c26;
}

.formTable {
  /* border: 1px solid #972c26; */
  box-shadow: 3px;
  margin-top: 3vh;
}

.detailsFormSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  row-gap: 2vh;
}

.formHeading {
  padding: 8px 0;
  font-weight: 500;
  font-size: 16px;
}

.showForm {
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 8px;
}
.formSelect {
  display: flex;
  gap: 2vw;
}

.formSelect > select {
  border: 2px solid #ccc;
  border-radius: 4px;
  width: 20vw;
  outline: none;
  padding: 4px 0;
}
.formSelect > input {
  border: 2px solid #ccc;
  border-radius: 4px;
  width: 20vw;
  outline: none;
  padding: 4px 4px;
}

.formSelect > select:focus {
  border: 2px solid #035ddb;
}
.formSelect > input:focus {
  border: 2px solid #035ddb;
}

.formInput > input {
  width: 10vw;
}
.formInput > select {
  width: 20vw;
}

.detailsFormInput {
  display: grid;
  padding: 12px 24px;
  grid-template-columns: repeat(3, 1fr);
  gap: 1vh 5vw;
  flex-wrap: wrap;
}

.formInput {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.table {
  width: 100%;
  margin-top: 4vh;
  border-collapse: collapse;
}

.table th,
.table td {
  /* border: 1px solid #972c265b; */
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #ced1d1;
  color: #000;
}

.table tbody tr {
  border-bottom: 1px solid #ccc;
  background-color: #fafafa;
}

/* .table tbody tr:hover {
  background-color: #e5e5e5;
} */

/* .table input {
  width: 100%;
} */

.saveButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveButton button {
  padding: 9px 15px;
  display: flex;
  background-color: #5783db;
  color: #fff;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #0055ff;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
}

.viewButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewButton button {
  color: #fff;
  background-color: #5783db;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0055ff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
}

.viewButton h1 {
  padding: 8px 0;
  font-weight: bold;
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .detailsFormSelect {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  .formInput > input,
  .formInput > select {
    width: 100%;
  }

  .detailsFormInput {
    grid-template-columns: 1fr;
    gap: 1vh 0;
  }

  .table th,
  .table td {
    padding: 6px;
    font-size: 14px;
  }

  .tableContainer {
    overflow-y: hidden;
  }

  .detailsFormSelect {
    display: flex;
    flex-direction: column;
  }
}
