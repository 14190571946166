.documentMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 2vw;
}

.documentSubOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
}

.buttonMain {
  color: #fff;
  background-color: #5783db;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0055ff;
    cursor: pointer;

    transition: all 0.3s ease-in-out;
    color: #fff;
  }
}

@media only screen and (max-width: 768px) {
  .documentMain {
    display: flex;
    flex-direction: column;
  }

  .documentSubOne {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .buttonMain {
    margin: 1vh 0;
  }
}
