.main {
  margin: 2vh;
}

.Header {
  display: flex;
  padding-bottom: 8vh;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.form {
  display: flex;
  gap: 4vh;
  flex-direction: column;
  align-items: center;
  margin-left: -25vw;
}

.inputContainer {
  display: grid;
  grid-template-columns: 12vw 2vw;
  gap: 1vw;
}

.inputContainer input {
  width: 25vw;
  border-radius: 4px;
  outline: none;
  border: 3px solid #ccc;
  padding: 6px 8px;
  font-size: 18px;
}

.inputContainer p {
  white-space: nowrap;
  color: red;
  font-size: 14px;
  height: 1px;
  font-weight: 500;
}

.inputContainer input:focus {
  border: 3px solid rgb(0, 118, 169);
}

.inputContainer > label {
  font-size: 18px;
  white-space: nowrap;
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.submit > button {
  border: none;
  padding: 12px 25px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #5783db;
  color: #fff;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .inputContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1vw;
  }

  .form {
    margin-left: 0;
  }

  .inputContainer input {
    width: 50vw;
    border-radius: 4px;
    outline: none;
    border: 3px solid #ccc;
    padding: 6px 8px;
    font-size: 18px;
  }
}
