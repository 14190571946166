.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loginContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 60%;
  border-radius: 10px;
  justify-content: center;
  box-shadow: 0 0 20px 8px #ccc;
  height: 80%;
}

.contentContainer {
  background-color: #5783db;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  height: 100%;
  color: #fff;
  background-image: linear-gradient(to right, #004bbd, #399dff);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vh;
  flex-direction: column;
  padding: 0 2vw;
}
.contentContainer h1 {
  color: #fff;
  font-size: 40px;
}
.contentContainer p {
  color: #fff;
  font-size: 20px;
}

.formConatiner {
  align-items: center;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  gap: 4vh;
  flex-direction: column;
  background-color: white;
  padding: 0 2vw;
}

.formConatiner h1 {
  font-size: 30px;
  margin-bottom: 2vh;
  width: 20vw;
}
.formConatiner p {
  font-size: 20px;
}

.formConatiner input {
  width: 20vw;
  height: 5vh;
  background-color: #eee;
  outline: none;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  padding: 4px 12px;
}

.formConatiner button {
  margin-bottom: -5vh;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  border: 3px solid #5783db;
  padding: 12px 45px;
  font-size: 16px;
  font-weight: 600;
  background-color: #fff;
}

.formConatiner button:hover {
  background-color: #5783db;
  color: #fff;
  transition: background-color 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .loginContainer {
    flex-direction: column;
    width: 80%;
    height: auto;
    border-radius: 0;
    box-shadow: none;
    box-shadow: 0 0 20px 8px #ccc;
  }

  .contentContainer {
    border-radius: 10px 10px 0 0;
    height: 50%;
    padding: 20px;
  }

  .formConatiner {
    border-radius: 0 0 10px 10px;
    height: 50%;
    padding: 20px;
  }

  .formConatiner button {
    margin-bottom: 0;
  }

  .formConatiner input {
    width: 100%;
  }
}
