.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeIcon {
  position: absolute;
  top: 10px;
  cursor: pointer;
  right: 30px;
}

.modal-content {
  background-color: white;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  width: 100%;
  text-align: center;
  /* height: 20%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-height: 500px;
  overflow-y: auto;
}

.modal-content h2,
.modal-content p {
  margin: 10px;
}

.modal-close {
  /* position: absolute; */
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  margin: 10px;
}

.modal-close:hover {
  color: #555;
}
