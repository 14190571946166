.indent-booking {
  display: flex;
  flex-direction: row;
  margin: 0 10%;
  justify-content: space-between;
}

.indent-booking-one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.indent-booking-one select,
.indent-booking-one input {
  width: 25vw;
  height: 4vh;
}

.select-table {
  width: 8vw;
  height: 4vh;
}

@media only screen and (max-width: 768px) {
  .indent-booking {
    display: flex;
    flex-direction: column;
  }

  .indent-booking-one {
    margin: 5% 0;
  }

  .select-table {
    width: 20vw;
    height: 4vh;
  }
}
