.mainContainer {
  padding: 0 20px;
}
.viewButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.viewButton button {
  color: #fff;
  background-color: #5783db;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #972c26;
    cursor: pointer;

    transition: all 0.3s ease-in-out;
    color: #fff;
  }
}

.heading {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  color: #972c26;
}

.formTable {
  /* border: 1px solid #972c26; */
  box-shadow: 3px;
  margin-top: 1vh;
}

.detailsFormSelect {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 12px 0;
  row-gap: 2vh;
  column-gap: 5vw;
}

.formInput input {
  height: 4vh;
}

.formHeading {
  padding: 8px 0;
  font-weight: 500;
  font-size: 16px;
}

.showForm {
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 8px;
  margin-top: 4px;
}

.formSelect {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.formSelect select {
  width: 15vw;
  height: 4vh;
}

.formInput > input {
  width: 10vw;
}
.formInput > select {
  width: 20vw;
}

.detailsFormInput {
  display: grid;
  padding: 12px 24px;
  grid-template-columns: repeat(3, 1fr);
  gap: 1vh 5vw;
  flex-wrap: wrap;
}

.formInput {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.table {
  width: 100%;
  margin-top: 2vh;
  border-collapse: collapse;
}

.table th,
.table td {
  /* border: 1px solid #972c265b; */
  padding: 8px;
  text-align: left;
}

.table tr {
  display: table;
  width: 100%;
  /* table-layout: fixed;  */
}

.table th {
  background-color: #ced1d1;
  color: #000;
  position: sticky;
  top: 0;
}

.table tbody tr:nth-child(1) {
  background-color: #fafafa;
}

.table tbody tr:hover {
  background-color: #e5e5e5;
}

.table thead {
  display: table-header-group;
}

.table tbody {
  display: block;
  height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
}

.table input {
  width: 100%;
}

.saveButton {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.saveButton button {
  padding: 9px 15px;
  display: flex;
  background-color: #5783db;
  color: #fff;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  &:hover {
    background-color: #972c26;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
}

.tableColumnOne {
  min-width: 105px;
  white-space: nowrap;
}

.tableColumnTwo {
  width: 80px;
  white-space: nowrap;
}

.saveButtonText {
  width: 100px;
}

@media only screen and (max-width: 768px) {
  .detailsFormSelect {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  .formInput > input,
  .formInput > select {
    width: 100%;
  }

  .detailsFormInput {
    grid-template-columns: 1fr;
    gap: 1vh 0;
  }

  .table th,
  .table td {
    padding: 6px;
    font-size: 14px;
  }

  .tableContainer {
    overflow-y: hidden;
  }

  .formSelect select {
    width: 36vw;
    height: 4vh;
  }

  .formSelectCustomer {
    grid-template-columns: 1fr;
  }

  .saveButton {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2vh;
  }

  .saveButtonText {
    margin-top: 2vh;
  }
}

@media only screen and (min-width: 768px) {
  .formSelectCustomer {
    grid-column: span 2;
    width: 200%;
  }
}
